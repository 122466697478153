export default {
  vouchers: 'Vouchery',
  voucher: 'Voucher',
  'voucher.value': 'Wartość vouchera {currency}',
  'vouchers.code': 'Kod vouchera',
  'vouchers.limit_usage': 'Limit użycia',
  'vouchers.max_cart_price': 'Max. wartość koszyka',
  'vouchers.min_cart_price': 'Min. wartość koszyka',
  'vouchers.type': 'Typ vochera',
  'vouchers.included_products': 'Uwzględnione produkty',
  'vouchers.excluded_products': 'Wykluczone produkty',
  'vouchers.cart_fixed': 'Kwotowy na koszyk',
  'vouchers.cart_percent': 'Procentowy na koszyk',
  'vouchers.product_fixed': 'Kwotowy na produkt',
  'vouchers.product_percent': 'Procentowy na produkt',
  'voucher.edit': 'Edytuj voucher',
  'voucher.excluded_categories': 'Wykluczone kategorie',
  'voucher.included_categories': 'Uwzględnione kategorie',
  'voucher.only_for_users': 'Tylko dla użytkowników',
};
