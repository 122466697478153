// @ts-nocheck

  import SmileOutlined from '@ant-design/icons/es/icons/SmileOutlined';
import FireOutlined from '@ant-design/icons/es/icons/FireOutlined';
import BookOutlined from '@ant-design/icons/es/icons/BookOutlined';
import ExperimentOutlined from '@ant-design/icons/es/icons/ExperimentOutlined';
import CalculatorOutlined from '@ant-design/icons/es/icons/CalculatorOutlined';
import CustomerServiceOutlined from '@ant-design/icons/es/icons/CustomerServiceOutlined';
import ReadOutlined from '@ant-design/icons/es/icons/ReadOutlined';
import ShoppingCartOutlined from '@ant-design/icons/es/icons/ShoppingCartOutlined';
import FundOutlined from '@ant-design/icons/es/icons/FundOutlined';
import DollarOutlined from '@ant-design/icons/es/icons/DollarOutlined';
import PercentageOutlined from '@ant-design/icons/es/icons/PercentageOutlined';
import UserOutlined from '@ant-design/icons/es/icons/UserOutlined';
import LockOutlined from '@ant-design/icons/es/icons/LockOutlined';
import TeamOutlined from '@ant-design/icons/es/icons/TeamOutlined';
import TrophyOutlined from '@ant-design/icons/es/icons/TrophyOutlined';
import RiseOutlined from '@ant-design/icons/es/icons/RiseOutlined';
import AlertOutlined from '@ant-design/icons/es/icons/AlertOutlined';
import ProjectOutlined from '@ant-design/icons/es/icons/ProjectOutlined';
import HistoryOutlined from '@ant-design/icons/es/icons/HistoryOutlined';
import SettingOutlined from '@ant-design/icons/es/icons/SettingOutlined';
import FolderOpenOutlined from '@ant-design/icons/es/icons/FolderOpenOutlined';
import HighlightOutlined from '@ant-design/icons/es/icons/HighlightOutlined';
import TranslationOutlined from '@ant-design/icons/es/icons/TranslationOutlined';
import AppstoreOutlined from '@ant-design/icons/es/icons/AppstoreOutlined';
import InteractionOutlined from '@ant-design/icons/es/icons/InteractionOutlined';
import GlobalOutlined from '@ant-design/icons/es/icons/GlobalOutlined';
import QuestionOutlined from '@ant-design/icons/es/icons/QuestionOutlined'
  export default {
    SmileOutlined,
FireOutlined,
BookOutlined,
ExperimentOutlined,
CalculatorOutlined,
CustomerServiceOutlined,
ReadOutlined,
ShoppingCartOutlined,
FundOutlined,
DollarOutlined,
PercentageOutlined,
UserOutlined,
LockOutlined,
TeamOutlined,
TrophyOutlined,
RiseOutlined,
AlertOutlined,
ProjectOutlined,
HistoryOutlined,
SettingOutlined,
FolderOpenOutlined,
HighlightOutlined,
TranslationOutlined,
AppstoreOutlined,
InteractionOutlined,
GlobalOutlined,
QuestionOutlined
  }