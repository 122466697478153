// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/node/app/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/home/node/app/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "path": "/user/login",
                "access": "loggedOut",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__login' */'/home/node/app/src/pages/User/login'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "reset",
                "path": "/user/reset-password",
                "access": "loggedOut",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__reset' */'/home/node/app/src/pages/User/reset'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/welcome",
        "name": "Welcome",
        "icon": "smile",
        "access": "dashboardPermission",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/home/node/app/src/pages/Welcome'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/courses",
        "name": "Courses",
        "icon": "fire",
        "access": "coursesPermission",
        "routes": [
          {
            "path": "/courses",
            "redirect": "/courses/list",
            "access": "coursesPermission",
            "exact": true
          },
          {
            "path": "/courses/list",
            "name": "List",
            "icon": "fire",
            "access": "courseListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Courses' */'/home/node/app/src/pages/Courses'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/courses/list/:course",
            "name": "Form",
            "icon": "book",
            "access": "courseDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Courses__form' */'/home/node/app/src/pages/Courses/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/courses/list/:course/:tab",
            "name": "Course Form",
            "icon": "book",
            "access": "courseDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Courses__form' */'/home/node/app/src/pages/Courses/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": false
          },
          {
            "path": "/courses/h5ps",
            "name": "H5Ps",
            "icon": "experiment",
            "access": "h5pListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__H5P' */'/home/node/app/src/pages/H5P'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/courses/h5ps/:h5p",
            "name": "H5Ps",
            "icon": "book",
            "access": "h5pDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__H5P__form' */'/home/node/app/src/pages/H5P/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/courses/h5ps/preview/:h5p",
            "name": "H5Ps",
            "icon": "book",
            "access": "h5pDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__H5P__preview' */'/home/node/app/src/pages/H5P/preview'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/courses/scorms",
            "name": "SCORMs",
            "icon": "experiment",
            "access": "scormListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Scorm' */'/home/node/app/src/pages/Scorm'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/courses/scorms/preview/:uuid",
            "name": "SCORMs",
            "icon": "experiment",
            "access": "scormDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Scorm__preview' */'/home/node/app/src/pages/Scorm/preview'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/courses/categories",
            "name": "Categories",
            "icon": "calculator",
            "access": "categoryListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Categories' */'/home/node/app/src/pages/Categories'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/courses/webinars",
            "name": "Webinars",
            "icon": "CustomerServiceOutlined",
            "access": "webinarListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Webinars' */'/home/node/app/src/pages/Webinars'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/courses/webinars/:webinar",
            "access": "webinarDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Webinars__form' */'/home/node/app/src/pages/Webinars/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/courses/webinars/:webinar/:tab",
            "access": "webinarDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Webinars__form' */'/home/node/app/src/pages/Webinars/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/courses/access",
            "name": "Access Requests",
            "icon": "experiment",
            "access": "courseAccessListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CourseAccess' */'/home/node/app/src/pages/CourseAccess'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/courses/quiz-reports",
            "name": "Quiz Reports",
            "icon": "read",
            "access": "coursesQuizReportsListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__QuizReports' */'/home/node/app/src/pages/QuizReports'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/courses/quiz-reports/:reportId",
            "hideInMenu": true,
            "name": "Details",
            "icon": "read",
            "access": "coursesQuizReportsListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__QuizReports__details' */'/home/node/app/src/pages/QuizReports/details'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/sales",
        "name": "Sales",
        "access": "salesPermission",
        "icon": "shopping-cart",
        "routes": [
          {
            "path": "/sales",
            "redirect": "/sales/orders",
            "access": "salesPermission",
            "exact": true
          },
          {
            "path": "/sales/orders",
            "name": "Orders",
            "icon": "fund",
            "access": "orderListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Orders' */'/home/node/app/src/pages/Orders'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sales/payments",
            "name": "Payments",
            "icon": "dollar",
            "access": "paymentListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Payments' */'/home/node/app/src/pages/Payments'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sales/vouchers",
            "name": "Vouchers",
            "icon": "percentage",
            "access": "voucherListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Vouchers' */'/home/node/app/src/pages/Vouchers'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sales/vouchers/:voucherId/",
            "access": "voucherDetailPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Vouchers__form' */'/home/node/app/src/pages/Vouchers/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/sales/products",
            "name": "Products",
            "icon": "shopping-cart",
            "access": "productsListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Products' */'/home/node/app/src/pages/Products'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sales/products/:id",
            "access": "productsDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Products__form' */'/home/node/app/src/pages/Products/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/sales/products/:id/:tab",
            "access": "productsDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Products__form' */'/home/node/app/src/pages/Products/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/users",
        "name": "Users",
        "access": "usersPermission",
        "icon": "user",
        "routes": [
          {
            "path": "/users",
            "redirect": "/users/list",
            "access": "usersPermission",
            "exact": true
          },
          {
            "path": "/users/list",
            "name": "List",
            "icon": "user",
            "access": "userListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Users' */'/home/node/app/src/pages/Users'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/users/list/new",
            "icon": "user",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Users__User' */'/home/node/app/src/pages/Users/User'), loading: LoadingComponent}),
            "access": "userCreatePermission",
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "Roles",
            "path": "/users/roles",
            "icon": "lock",
            "access": "roleListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Roles' */'/home/node/app/src/pages/Roles'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Permissions",
            "path": "/users/roles/:name",
            "icon": "lock",
            "access": "roleDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Roles__form' */'/home/node/app/src/pages/Roles/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/users/groups",
            "name": "User Groups",
            "icon": "team",
            "access": "userGroupListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserGroups' */'/home/node/app/src/pages/UserGroups'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/users/groups/:group",
            "name": "Form",
            "icon": "team",
            "access": "userGroupDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserGroups__form' */'/home/node/app/src/pages/UserGroups/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/users/:user/:tab",
            "icon": "user",
            "access": "userDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Users__User' */'/home/node/app/src/pages/Users/User'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/users/fields",
            "icon": "user",
            "access": "userDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Users__fields' */'/home/node/app/src/pages/Users/fields'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/my-profile",
        "name": "My Profile",
        "access": "userProfilePermission",
        "hideInMenu": true,
        "routes": [
          {
            "path": "/my-profile",
            "redirect": "/my-profile/general",
            "access": "userProfilePermission",
            "exact": true
          },
          {
            "path": "/my-profile/:tab",
            "name": "My Profile",
            "access": "userProfilePermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Users__myProfile' */'/home/node/app/src/pages/Users/myProfile'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/teacher",
        "name": "Teacher",
        "access": "teacherPermission",
        "icon": "experiment",
        "routes": [
          {
            "path": "/teacher",
            "redirect": "/teacher/subjects",
            "access": "teacherPermission",
            "exact": true
          },
          {
            "path": "/teacher/subjects",
            "name": "Subjects",
            "icon": "book",
            "access": "teacherSubjectListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TeacherSubjects' */'/home/node/app/src/pages/TeacherSubjects'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/teacher/subjects/:subjectId",
            "name": "Form",
            "icon": "book",
            "access": "teacherSubjectDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TeacherSubjects__form' */'/home/node/app/src/pages/TeacherSubjects/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/teacher/subjects/:subjectId/:tab",
            "name": "Subject Form",
            "icon": "book",
            "access": "teacherSubjectDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TeacherSubjects__form' */'/home/node/app/src/pages/TeacherSubjects/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/competency-challenges",
        "name": "Competency challenges",
        "access": "competencyChallengesPermission",
        "icon": "trophy",
        "routes": [
          {
            "path": "/competency-challenges",
            "redirect": "/competency-challenges/list",
            "access": "competencyChallengesPermission",
            "exact": true
          },
          {
            "path": "/competency-challenges/list",
            "name": "List",
            "access": "competencyChallengesListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompetencyChallenges' */'/home/node/app/src/pages/CompetencyChallenges'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/competency-challenges/:id/:tab",
            "name": "Competency challenge",
            "access": "competencyChallengesFormPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompetencyChallenges__form' */'/home/node/app/src/pages/CompetencyChallenges/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "name": "Analytics",
        "path": "/analytics",
        "access": "analyticsPermission",
        "icon": "rise",
        "routes": [
          {
            "path": "/analytics",
            "redirect": "/analytics/notifications",
            "access": "analyticsPermission",
            "exact": true
          },
          {
            "name": "Notifications",
            "icon": "alert",
            "path": "/analytics/notifications",
            "access": "notificationListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Notifications' */'/home/node/app/src/pages/Notifications'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "reports",
            "icon": "project",
            "path": "/analytics/reports",
            "access": "reportListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Reports' */'/home/node/app/src/pages/Reports'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Logs",
            "icon": "history",
            "path": "/analytics/logs",
            "access": "trackerListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Logs' */'/home/node/app/src/pages/Logs'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "Configuration",
        "path": "/configuration",
        "access": "configurationPermission",
        "icon": "setting",
        "routes": [
          {
            "path": "/configuration",
            "redirect": "/configuration/files",
            "access": "configurationPermission",
            "exact": true
          },
          {
            "path": "/configuration/files",
            "name": "Files",
            "icon": "folderOpen",
            "access": "fileListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Files' */'/home/node/app/src/pages/Files'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/configuration/settings",
            "name": "settings",
            "icon": "calculator",
            "access": "settingListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Settings' */'/home/node/app/src/pages/Settings'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/configuration/settings/:tab",
            "name": "settings",
            "icon": "calculator",
            "access": "settingListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Settings' */'/home/node/app/src/pages/Settings'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/configuration/templates",
            "name": "Templates",
            "icon": "highlight",
            "access": "templateDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Templates' */'/home/node/app/src/pages/Templates'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/configuration/templates/:template",
            "name": "Templates",
            "icon": "highlight",
            "access": "templateDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Templates' */'/home/node/app/src/pages/Templates'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/configuration/templates/:template/:id",
            "name": "Templates",
            "icon": "highlight",
            "access": "templateDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Templates__form' */'/home/node/app/src/pages/Templates/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "Translations",
            "icon": "translation",
            "path": "/configuration/translations",
            "access": "translationListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Translations' */'/home/node/app/src/pages/Translations'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Panel Translations",
            "icon": "translation",
            "path": "/configuration/admin_translations",
            "access": "adminTranslationListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Translations__admin' */'/home/node/app/src/pages/Translations/admin'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "Other activities",
        "path": "/other",
        "access": "otherPermission",
        "icon": "appstore",
        "routes": [
          {
            "path": "/other",
            "redirect": "/other/consultations",
            "access": "otherPermission",
            "exact": true
          },
          {
            "path": "/other/consultations",
            "name": "Consultations",
            "icon": "interaction",
            "access": "consultationListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Consultations' */'/home/node/app/src/pages/Consultations'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/other/consultation-access",
            "name": "Consultation Requests",
            "icon": "experiment",
            "access": "consultationAccessListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ConsultationsAccess' */'/home/node/app/src/pages/ConsultationsAccess'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/other/consultations/:consultation",
            "access": "consultationDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Consultations__form' */'/home/node/app/src/pages/Consultations/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/other/consultations/:consultation/:tab",
            "access": "consultationDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Consultations__form' */'/home/node/app/src/pages/Consultations/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "StationaryEvents",
            "icon": "global",
            "path": "/other/stationary-events",
            "access": "stationaryEventsListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__StationaryEvents' */'/home/node/app/src/pages/StationaryEvents'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/other/stationary-events/:id",
            "access": "stationaryEventsDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__StationaryEvents__form' */'/home/node/app/src/pages/StationaryEvents/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/other/stationary-events/:id/:tab",
            "access": "stationaryEventsDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__StationaryEvents__form' */'/home/node/app/src/pages/StationaryEvents/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/other/questionnaire",
            "name": "Questionnaire",
            "icon": "question",
            "access": "questionnaireListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Questionnaire' */'/home/node/app/src/pages/Questionnaire'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/other/questionnaire/:questionnaireId",
            "name": "Questionnaire Form",
            "access": "questionnaireDetailPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Questionnaire__form' */'/home/node/app/src/pages/Questionnaire/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/other/pages",
            "name": "Pages",
            "icon": "read",
            "access": "pageListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Pages' */'/home/node/app/src/pages/Pages'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/other/pages/:page",
            "name": "Form",
            "icon": "read",
            "access": "pageDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Pages__form' */'/home/node/app/src/pages/Pages/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/other/tasks",
            "name": "Tasks",
            "icon": "read",
            "access": "taskListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tasks' */'/home/node/app/src/pages/Tasks'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/other/tasks/:task",
            "name": "Form",
            "icon": "read",
            "access": "taskDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tasks__form' */'/home/node/app/src/pages/Tasks/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "access": "dashboardPermission",
        "path": "/",
        "redirect": "/welcome",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/node/app/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
